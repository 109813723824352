import confetti from "canvas-confetti";
import Chance from "chance";
import { LitElement, css, html, nothing } from "lit";
import { customElement, state } from "lit/decorators";
import { assign, createActor, createMachine } from "xstate";
import { notWants } from "./notWants.json";
import { things } from "./things.json";
import { wants } from "./wants.json";

const chance = new Chance();

const picker = (() => {
  let options = [...things];

  return {
    pick(): string | undefined {
      if (options.length === 0) {
        return "";
      }
      const thing = chance.pickone(options);
      options = options.filter((option) => option !== thing);
      return thing;
    },
    reset() {
      options = [...things];
    },
  };
})();

function endState(amountOfWant: number) {
  if (amountOfWant < 0) {
    return {
      message:
        "You are the zenith of aversion, where the very notion of its presence is anathema to your existence.",
      options: [
        { label: "I'm done with this game! 😡", value: "done" },
        { label: "I want to play again! 🎉", value: "idle" },
      ],
    };
  } else {
    return {
      message:
        "You are at the pinnacle of obsession, where your very existence hinges on the acquisition of it.",
      options: [
        { label: "I'm done with this game! 😡", value: "done" },
        { label: "I want to play again! 🎉", value: "idle" },
      ],
    };
  }
}

const wantMachine = createMachine({
  initial: "idle",
  context: {
    amountOfWant: 0,
    amountOfWantDescription: "",
    thing: "",
    message:
      'Welcome to the game where you can want things! 🎉 Now, the rules of the game are pretty simple. If you want the thing, press "Yes" or if for some reason you don\'t want the thing, press "No".',
    options: [{ label: "Okay, I'm ready! 🎉", value: "start" }],
  },
  states: {
    idle: {
      on: { start: "playing" },
      entry: assign({
        amountOfWant: 0,
        amountOfWantDescription: "",
        thing: "",
        message:
          'Welcome to the game where you can want things! 🎉 Now, the rules of the game are pretty simple. If you want the thing, press "Yes" or if for some reason you don\'t want the thing, press "No".',
        options: [{ label: "Okay, I'm ready! 🎉", value: "start" }],
      }),
    },
    done: {
      type: "final",
      entry: assign({
        message: "You're done with the game! 😡",
        options: [],
      }),
    },
    playing: {
      on: {
        yes: {
          target: "playing",
          actions: assign(({ context }) => {
            const thing = picker.pick();

            if (!thing) {
              return endState(context.amountOfWant);
            }

            const newAmountOfWant =
              context.amountOfWant + chance.integer({ min: 1, max: 3 });

            return {
              thing,
              amountOfWant: newAmountOfWant,
              amountOfWantDescription: chance.pickone(wants),
              message: "",
              options: [
                { label: "No I don't want that 🙅🏾‍♀️", value: "no" },
                { label: "Yes I want that 🙋🏾‍♀️", value: "yes" },
              ],
            };
          }),
        },
        no: {
          target: "playing",
          actions: assign(({ context }) => {
            const thing = picker.pick();

            if (!thing) {
              return endState(context.amountOfWant);
            }

            const newAmountOfWant =
              context.amountOfWant - chance.integer({ min: 1, max: 3 });

            return {
              thing,
              amountOfWant: newAmountOfWant,
              amountOfWantDescription: chance.pickone(notWants),
              message: "",
              options: [
                { label: "No I don't want that 🙅🏾‍♀️", value: "no" },
                { label: "Yes I want that 🙋🏾‍♀️", value: "yes" },
              ],
            };
          }),
        },
        done: "done",
        idle: "idle",
      },
      entry: assign(() => {
        picker.reset();
        const thing = picker.pick();

        return {
          thing,
          message: "",
          options: [
            { label: "No I don't want that 🙅🏾‍♀️", value: "no" },
            { label: "Yes I want that 🙋🏾‍♀️", value: "yes" },
          ],
        };
      }),
    },
  },
});

function randomInRange(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

@customElement("ha-want-that")
export class HaWantThat extends LitElement {
  @state()
  private machine = createActor(wantMachine);

  override connectedCallback() {
    super.connectedCallback();

    this.machine.start();

    this.machine.subscribe(() => {
      this.requestUpdate();
    });
  }

  override disconnectedCallback() {
    super.disconnectedCallback();
    this.machine.stop();
  }

  __renderThing(thing: string) {
    if (!thing) {
      return nothing;
    }
    return html`<p class="thing">${thing}</p>`;
  }

  __renderAmountOfWant(amountOfWantDescription: string, amountOfWant: number) {
    if (!amountOfWantDescription) {
      return nothing;
    }

    return html`<p class="amount-of-want">
        &ldquo;${amountOfWantDescription}&rdquo; &mdash; 🤷🏾‍♀️
      </p>
      <meter
        class="amount-of-want-meter"
        min="-300"
        low="-100"
        optimum="300"
        high="100"
        max="300"
        value=${amountOfWant}
      ></meter> `;
  }

  __renderMessage(message: string) {
    if (!message) {
      return nothing;
    }

    return html`<p class="message">${message}</p>`;
  }

  override render() {
    const { context } = this.machine.getSnapshot();

    if (!context) {
      return;
    }

    return html`<div class="game">
      ${this.__renderMessage(context.message)}
      ${this.__renderThing(context.thing)}
      <div class="buttons">
        ${context.options.map(
          (option) =>
            html`<button
              class="button ${option.value}"
              @click=${() => {
                this.machine.send({ type: option.value });
                if (option.value === "yes") {
                  confetti({
                    angle: randomInRange(55, 125),
                    spread: randomInRange(50, 70),
                    particleCount: randomInRange(50, 100),
                    origin: { y: 0.6 },
                  });
                }
              }}
            >
              <span>${option.label}</span>
            </button>`,
        )}
      </div>
      ${this.__renderAmountOfWant(
        context.amountOfWantDescription,
        context.amountOfWant,
      )}
    </div>`;
  }

  static override styles = css`
    .game {
      font-size: 1rem;
      margin: 0 auto;
      padding: 1rem;
    }

    @media (min-width: 768px) {
      .game {
        max-width: 52ch;
        padding: 2rem;
        border-radius: 0.5rem;
        border: 1px solid #e5e5e5;
      }
    }

    .amount-of-want {
      font-size: 1rem;
      margin: 2rem 0 0 0;
      color: #282828;
      text-align: center;
      font-style: italic;
    }

    .amount-of-want-meter {
      width: 100%;
      margin-top: 1rem;
    }

    .thing {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.2;
      margin: 0;
      color: #282828;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      min-height: 25vh;
    }

    @media (min-width: 768px) {
      .thing {
        font-size: 2rem;
        min-height: 8rem;
      }
    }

    .message {
      font:
        1rem / 1.5 Lora,
        serif;
      min-height: 8rem;
    }

    .buttons {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
    }

    button {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 1rem 1.5rem;
      border: none;
      border-radius: 0.25rem;
      background-color: #f5f5bb;
      color: #000;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      font:
        0.9rem / 1.5 Lora,
        serif;

      &:hover {
        background-color: #e5e5ab;
      }
    }

    button.yes {
      background-color: #a5f5a5;

      &:hover {
        background-color: #95e595;
      }
    }

    @media (min-width: 768px) {
      button {
        font-size: 1rem;
      }
    }
  `;
}
