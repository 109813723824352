{
  "notWants": [
    "I'm indifferent to it.",
    "It's not really for me.",
    "I'm not particularly interested.",
    "It doesn't catch my eye.",
    "I'm slightly averse to it.",
    "Not something I'd choose.",
    "It's not on my list.",
    "I'm not drawn to it.",
    "I'd probably pass on it.",
    "It's not my cup of tea.",
    "I'm not feeling it.",
    "It doesn't appeal to me.",
    "I'm somewhat disinterested.",
    "I'm leaning away from it.",
    "It's not appealing to me.",
    "I'm not keen on it.",
    "I'm less than interested.",
    "It doesn't resonate with me.",
    "I'm not very interested.",
    "It's not something I want.",
    "I'm quite uninterested.",
    "It's not something I'm into.",
    "I'm actively disinterested.",
    "I'm fairly certain I don't want it.",
    "I'm pretty sure it's not for me.",
    "I'm not fond of it.",
    "It's not something I'd enjoy.",
    "I'm quite sure I don't want it.",
    "I'm not attracted to it.",
    "It's definitely not for me.",
    "I'm strongly against wanting it.",
    "I'm very uninterested.",
    "I'm decidedly against it.",
    "It's something I'd rather not have.",
    "I'm seriously disinterested.",
    "I'm very much not interested.",
    "I'd definitely avoid it.",
    "I'm wholly uninterested.",
    "I'm completely disinterested.",
    "I'm totally against having it.",
    "I'm absolutely not interested.",
    "It's something I'd actively avoid.",
    "I'm firmly against wanting it.",
    "I'd strongly prefer not to have it.",
    "I'm vehemently disinterested.",
    "I'd categorically say no to it.",
    "I'm entirely uninterested.",
    "I'm adamantly against it.",
    "I'm staunchly disinterested.",
    "It's something I strongly don't want.",
    "I'm thoroughly against it.",
    "I'm utterly disinterested.",
    "I'm deeply averse to it.",
    "I'm passionately against it.",
    "I'm fervently not interested.",
    "I'm vehemently opposed to it.",
    "I'm aggressively disinterested.",
    "I'm resolutely against it.",
    "I'm starkly against wanting it.",
    "I'm rigorously uninterested.",
    "I'm unwaveringly against it.",
    "I'm emphatically not interested.",
    "I'm fiercely opposed to it.",
    "I'm intensely disinterested.",
    "I'm overwhelmingly against it.",
    "I'm implacably disinterested.",
    "I'm uncompromisingly against it.",
    "I'm inflexibly not interested.",
    "I'm unyieldingly opposed to it.",
    "I'm categorically against it.",
    "I'm irreversibly disinterested.",
    "I'm inexorably against it.",
    "I'm unconditionally not interested.",
    "I'm terminally disinterested.",
    "I'm irrevocably against it.",
    "I'm unalterably opposed.",
    "I'm irremediably disinterested.",
    "I'm undeniably against it.",
    "I'm incontrovertibly not interested.",
    "I'm indubitably opposed.",
    "I'm unmistakably against it.",
    "I'm definitively disinterested.",
    "I'm irreconcilably opposed.",
    "I'm unassailably against it.",
    "I'm irrefutably not interested.",
    "I'm insurmountably disinterested.",
    "I'm implacably against it.",
    "I'm invincibly opposed.",
    "I'm unchallengeably against it.",
    "I'm insuperably disinterested.",
    "I'm indomitably against it.",
    "I'm unimpeachably not interested.",
    "I'm invulnerably opposed.",
    "I'm impregnably against it.",
    "I'm impermeably disinterested.",
    "I'm impenetrably opposed.",
    "I'm inviolably against it.",
    "I'm intransigently not interested.",
    "I'm insurpassably opposed.",
    "I'm at the zenith of aversion, where the very notion of its presence is anathema to my existence."
  ]
}
