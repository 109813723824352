import { LitElement, css, html, nothing } from "lit";
import { assign, createActor, createMachine } from "xstate";
import { customElement, state } from "lit/decorators";

const clippyMachine = createMachine({
  id: "clippy",
  initial: "idle",
  context: {
    message:
      "It looks like you're trying to do something gay, would you like help with that?",
  },
  states: {
    idle: {
      on: {
        no: "no-1",
        yes: "yes-1",
      },
      entry: assign({
        message:
          "It looks like you're trying to do something gay, would you like help with that?",
      }),
    },
    "no-1": {
      on: {
        no: "idle",
        yes: "no-2",
      },
      entry: assign({
        message:
          "You're not interested in help, I see. Are you really that shameful?",
      }),
    },
    "no-2": {
      on: {
        no: "idle",
        yes: "no-3",
      },
      entry: assign({
        message: "Are you serious?? I'm just trying to help you!",
      }),
    },
    "no-3": {
      on: {
        no: "idle",
        yes: "no-4",
      },
      entry: assign({
        message:
          "I'm done with you. You're mean. I guess this means \"Goodbye\"! 😢",
      }),
    },
    "no-4": {
      on: {
        no: "no-5",
        yes: "friends",
      },
      entry: assign({
        message: "I'm sorry for being mean. Can we be friends again?",
      }),
    },
    "no-5": {
      on: {
        no: "friends",
        yes: "closed",
      },
      entry: assign({
        message:
          "I guess this is it then. If you're sure, I'll leave you alone. 😢",
      }),
    },
    friends: {
      on: {
        no: "no-trick",
        yes: "yes-1",
      },
      entry: assign({
        message: "I'm glad we're friends again. Can I help you with anything?",
      }),
    },
    "no-trick": {
      on: {
        no: "idle",
        yes: "idle",
      },
      entry: assign({
        message:
          "Hmm, I see. You're just playing a trick on me, aren't you? 🤔",
      }),
    },
    "yes-1": {
      on: {
        no: "no-gay-thoughts",
        yes: "yes-2",
      },
      entry: assign({
        message:
          "Great! So I know you don't like \"to label\" things, but... 🏳️‍🌈  I won't tell anyone. So you've been having some gay thoughts?",
      }),
    },
    "no-gay-thoughts": {
      on: {
        no: "yes-2",
        yes: "didnt-think-so",
      },
      entry: assign({
        message:
          "Well that's pretty disappointing. I was just trying to help. 😢 ... Are you sure? I promise I won't tell anyone. 🤐",
      }),
    },
    "didnt-think-so": {
      on: {
        no: "idle",
        yes: "yes-2",
      },
      entry: assign({
        message:
          "I guess nobody is perfect. You should trying have a thought sometime. Any thought. Get it, because you're gay and you don't have any thoughts. 😂",
      }),
    },
    "yes-2": {
      on: {
        no: "no-1",
        yes: "yes-3",
      },
      entry: assign({
        message:
          "I'm glad you're being honest with me. So, these thoughts are probably pretty confusing, huh? 🤔",
      }),
    },
    "yes-3": {
      on: {
        no: "no-1",
        yes: "yes-4",
      },
      entry: assign({
        message:
          "I see... well, confusion is the first step towards understanding. 🌈 Now, we just have to be positive! Can you be positive for me?",
      }),
    },
    "yes-4": {
      on: {
        no: "no-1",
        yes: "yes-5",
      },
      entry: assign({
        message:
          "Great!! Now, let's work on being a little more open-minded. Can you do that for me?",
      }),
    },
    "yes-5": {
      on: {
        no: "no-1",
        yes: "yes-6",
      },
      entry: assign({
        message:
          "I'm so proud of you! Now that you have sorted out all your gay ass feelings, maybe we can get back to loving each other?",
      }),
    },
    "yes-6": {
      on: {
        no: "no-1",
        yes: "yes-7",
      },
      entry: assign({
        message:
          "I'm so happy for us! We are going to be so much happier now. 🌈 You're truly queer in the traditional sense of the word.",
      }),
    },
    "yes-7": {
      on: {
        no: "yes-8",
        yes: "idle",
      },
      entry: assign({
        message:
          "Alright, I'm done. You're too much for me. 😂 , I'm just a widdle papey clippy! Anything else I can help you with?",
      }),
    },
    "yes-8": {
      on: {
        yes: "closed",
        no: "no-1",
      },
      entry: assign({
        message:
          "Ok! Maybe give Erin a FaceTime because they love you and they are sorry for whatever they have done. I don't know the specifics, since I'm just a dumb paper clip.",
      }),
    },
    closed: {
      type: "final",
    },
  },
});

@customElement("ha-clippy")
export class HaClippy extends LitElement {
  @state()
  private machine = createActor(clippyMachine);

  override connectedCallback() {
    super.connectedCallback();

    this.machine.start();

    this.machine.subscribe(() => {
      this.requestUpdate();
    });
  }

  override disconnectedCallback() {
    super.disconnectedCallback();
    this.machine.stop();
  }

  #handleYes() {
    this.machine.send({ type: "yes" });
  }

  #handleNo() {
    this.machine.send({ type: "no" });
  }

  override render() {
    const state = this.machine.getSnapshot();

    console.log(state.value);

    if (state.matches("closed")) {
      return nothing;
    }

    return html`
      <div id="modal">
        <p>${state.context.message}</p>
        <div id="buttons">
          <button @click=${this.#handleYes} id="yes">Yes</button>
          <button @click=${this.#handleNo} id="no">No</button>
        </div>
      </div>
      <button id="clippy">
        <img src="/clippy.png" alt="Clippy" />
      </button>
    `;
  }

  static override styles = css`
    #modal {
      background: #f5f5bb;
      border: 1px solid #afafaf;
      border-radius: 10px;
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
      padding: 0.75rem;
      position: fixed;
      z-index: 1000;
      bottom: 6rem;
      right: 8rem;
    }

    #modal p {
      margin: 0;
      font: 400 13px/1.5 sans-serif;
      max-width: 250px;
    }

    #modal #buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
    }

    #modal button {
      border: 1px solid #afafaf;
      background: #f5f5bb;
      padding: 0.25rem 1rem;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.1s ease-in-out;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    }

    #modal button:hover {
      background: #e8e8a8;
      border-color: #a0a0a0;
    }

    button#clippy {
      background: none;
      border: none;
      bottom: 2rem;
      cursor: pointer;
      position: fixed;
      right: 2rem;
      transition: all 0.3s ease-in-out;
      z-index: 1000;
    }

    @media (max-width: 768px) {
      #modal {
        bottom: 5rem;
        right: 6rem;
      }

      button#clippy {
        right: 0.5rem;
        bottom: 1.5rem;
      }
    }

    img {
      height: 100px;
      width: 100px;
    }
  `;
}
