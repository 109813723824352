import { LitElement, html, css } from "lit";
import {
  isAfter,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns";
import { customElement, property } from "lit/decorators";

const endDate = new Date("2024-03-07T19:00:00.000Z");

@customElement("ha-count-down")
export class HaCountDown extends LitElement {
  #inverval = 0;

  @property()
  override title = "Countdown to love, sex and magic";

  renderClock() {
    if (isAfter(new Date(), endDate)) {
      return html`<p>The time has arrived.</p>`;
    }

    const currentDate = new Date();
    const daysUntil = differenceInDays(endDate, currentDate);
    const hoursUntil = differenceInHours(endDate, currentDate) % 24;
    const minutesUntil = differenceInMinutes(endDate, currentDate) % 60;
    const secondsUntil = differenceInSeconds(endDate, currentDate) % 60;

    const pad = (value: number) => String(value).padStart(2, "0");

    return html`
      <div class="clock">
        <div class="number">
          <span class="subtitle">Days</span
          ><span class="date">${pad(daysUntil)}</span>
        </div>
        <div class="divider"></div>
        <div class="number">
          <span class="subtitle">Hours</span
          ><span class="date">${pad(hoursUntil)}</span>
        </div>
        <div class="divider"></div>
        <div class="number">
          <span class="subtitle">Minutes</span
          ><span class="date">${pad(minutesUntil)}</span>
        </div>
        <div class="divider"></div>
        <div class="number">
          <span class="subtitle">Seconds</span
          ><span class="date">${pad(secondsUntil)}</span>
        </div>
      </div>
    `;
  }

  override connectedCallback() {
    super.connectedCallback();

    const storedTitle = localStorage.getItem("ha-count-down-title");

    if (storedTitle) {
      this.title = storedTitle;
    }

    this.#inverval = setInterval(() => {
      this.requestUpdate();
    }, 1000);
  }

  override disconnectedCallback() {
    super.disconnectedCallback();
    clearInterval(this.#inverval);
  }

  handleTitleChange(event: InputEvent) {
    const target = event.target as HTMLParagraphElement;
    this.title = target.textContent || "";
    localStorage.setItem("ha-count-down-title", this.title);
  }

  override render() {
    return html`
      <div>
        <h1 contenteditable @input=${this.handleTitleChange} class="title">
          ${this.title}
        </h1>
        <div class="clock-container">${this.renderClock()}</div>
      </div>
    `;
  }

  static override styles = css`
    .title {
      text-align: center;
      max-width: 80vw;
      margin: 0 auto;
      margin-bottom: 2rem;
    }

    .clock-container {
      display: flex;
      justify-content: center;
    }

    .clock {
      align-items: center;
      border: 1px solid var(--faded-1);
      border-radius: 0.25rem;
      display: grid;
      grid-template-columns: minmax(6rem, 1fr) 1px 6rem 1px 6rem 1px 6rem;
    }

    .clock .number {
      align-items: center;
      display: flex;
      flex-direction: column-reverse;
      gap: 0.25rem;
      padding: 1rem;
    }

    .clock .subtitle {
      color: var(--faded-2);
      font-size: 0.6rem;
      line-height: 1;
      text-transform: uppercase;
    }

    .clock .date {
      font-size: 2.5rem;
      font-weight: 600;
      line-height: 1;
    }

    .clock .divider {
      background-color: var(--faded-1);
      height: 4rem;
      padding: 0;
      width: 1px;
    }
  `;
}
