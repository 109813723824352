{
  "wants": [
    "It might be nice to have.",
    "I wouldn't mind having it.",
    "It's something I'm considering.",
    "It could be useful.",
    "I'm slightly interested in it.",
    "It's on my maybe list.",
    "I've thought about getting it.",
    "I'm leaning towards wanting it.",
    "It's caught my eye.",
    "I'm somewhat interested.",
    "It's on my radar.",
    "I'm tentatively interested.",
    "I see some appeal in it.",
    "It's growing on me.",
    "I'm mildly interested.",
    "It's something I could use.",
    "I wouldn't say no to it.",
    "I'm starting to want it.",
    "It's definitely tempting.",
    "I'm fairly interested.",
    "It's on my list of wants.",
    "I'm getting keener on it.",
    "I'm considering it more seriously.",
    "It's something I'm drawn to.",
    "I've got my eye on it.",
    "I'm feeling a pull towards it.",
    "It's becoming more appealing.",
    "I'm quite interested.",
    "It's on my wish list.",
    "I'm more and more interested.",
    "I'm definitely considering it.",
    "I'm pretty keen on it.",
    "It's something I'm really considering.",
    "I'm very interested.",
    "It's highly tempting.",
    "I'm strongly considering it.",
    "I find it very appealing.",
    "I'm really drawn to it.",
    "I'm quite keen on it.",
    "It's something I'm very interested in.",
    "I'm highly interested.",
    "I've got a strong inclination towards it.",
    "I'm deeply interested.",
    "I really see the value in it.",
    "It's something I'm craving.",
    "I'm very much interested.",
    "It's becoming a strong desire.",
    "I'm extremely interested.",
    "I've got a serious craving for it.",
    "It's something I really want.",
    "I'm seriously considering it.",
    "I'm eagerly interested.",
    "It's something I'm yearning for.",
    "I've got a strong desire for it.",
    "I'm deeply craving it.",
    "It's something I'm passionately interested in.",
    "I'm fervently desiring it.",
    "I really want it.",
    "I've got a burning desire for it.",
    "I'm intensely interested.",
    "I'm absolutely craving it.",
    "I'm desperately wanting it.",
    "It's something I need in my life.",
    "I can't stop thinking about it.",
    "I'm obsessing over it.",
    "I'm dying to have it.",
    "It's something I can't live without.",
    "I'm passionately yearning for it.",
    "I'm achingly desiring it.",
    "I'm fervently wishing for it.",
    "I'm intensely craving it.",
    "I'm overwhelmingly drawn to it.",
    "I'm desperately in need of it.",
    "I'm utterly obsessed with it.",
    "I'm absolutely dying for it.",
    "It's something I'm madly in love with.",
    "I'm fanatically desiring it.",
    "I'm crazily obsessed with it.",
    "I'm vehemently craving it.",
    "I'm manically desiring it.",
    "I'm fervidly interested.",
    "I'm rabidly passionate about it.",
    "I'm wildly obsessed with it.",
    "I'm desperately yearning for it with every fiber of my being.",
    "I'm uncontrollably desiring it.",
    "I'm frenziedly passionate about it.",
    "I'm explosively interested.",
    "I'm supremely obsessed.",
    "I'm all-consumingly in need of it.",
    "I'm ravenously desiring it.",
    "I'm voraciously craving it.",
    "I'm insatiably yearning for it.",
    "I'm passionately obsessed with it.",
    "I'm deliriously desiring it.",
    "I'm overwhelmingly obsessed.",
    "I'm transcendently craving it.",
    "I'm maniacally passionate about it.",
    "I'm cataclysmically interested.",
    "I'm universe-shatteringly desiring it.",
    "I'm at the pinnacle of obsession, where my very existence hinges on its acquisition."
  ]
}
