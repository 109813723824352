{
  "things": [
    "Do you want a perfectly toasted slice of bread? 🍞",
    "Do you want to find a rare, glowing mushroom in a forest? 🍄",
    "Do you want a pen that never runs out of ink?",
    "Do you want to accidentally discover a new planet? 🪐",
    "Do you want a pair of socks that always match? 🧦",
    "Do you want to experience a day where you can talk to animals? 🐾",
    "Do you want a coffee that never gets cold? ☕",
    "Do you want to find a door that leads to an alternate universe?",
    "Do you want a plant that sings lullabies? 🌱",
    "Do you want to win an impromptu pie-eating contest? 🥧",
    "Do you want a keychain that can teleport you home? 🔑",
    "Do you want to invent a language that everyone can understand? 💬",
    "Do you want a cloud that you can sit on? ☁️",
    "Do you want to receive a letter from your future self?",
    "Do you want a mirror that shows you what you'll look like in 20 years? 🪞",
    "Do you want to discover a cure for hiccups?",
    "Do you want a hat that changes color with your mood? 🎩",
    "Do you want to stumble upon a hidden city underwater? 🌊",
    "Do you want a book that writes stories tailor-made for you? 📚",
    "Do you want to have a picnic on a floating island in the sky?",
    "Do you want shoes that let you walk on walls? 👟",
    "Do you want to find a map that leads to your heart's true desire? 🗺️",
    "Do you want a pizza that regenerates a slice after you take one? 🍕",
    "Do you want to experience a day lived backwards?",
    "Do you want a bubble that can withstand a fall from any height? 💭",
    "Do you want to paint a masterpiece with colors not yet named? 🎨",
    "Do you want a diary that gives you advice when you write in it?",
    "Do you want to catch a shooting star in a jar? ⭐",
    "Do you want a spoon that makes any meal taste incredible? 🥄",
    "Do you want to find a secret society of time travelers?",
    "Do you want a candle that burns with a flame in your favorite color? 🕯️",
    "Do you want to invent a machine that can clean the oceans? 🌍",
    "Do you want a necklace that allows you to breathe underwater? 🧜‍♀️",
    "Do you want to learn the art of invisibility?",
    "Do you want a pet dragon that fits in your pocket? 🐉",
    "Do you want a pair of glasses that translate any language? 👓",
    "Do you want to discover an ancient spell for eternal happiness? ✨",
    "Do you want a swing that takes you over the moon? 🌙",
    "Do you want a guitar that tunes itself to the mood of the room? 🎸",
    "Do you want to find a potion that lets you live multiple lives? 🧪",
    "Do you want a tree that grows money instead of leaves? 💸",
    "Do you want to wake up with the ability to fly? 🕊️",
    "Do you want a watch that can pause time? ⌛",
    "Do you want to build a house out of clouds?",
    "Do you want a tattoo that changes its design daily? 🦋",
    "Do you want to discover a hidden path that leads to a world of giants?",
    "Do you want an ice cream that never melts? 🍨",
    "Do you want to find a crystal that can store memories? 💎",
    "Do you want a magic carpet that can take you anywhere in the blink of an eye? 🧞‍♂️",
    "Do you want to learn the secret language of trees? 🌳",
    "Do you want a ring that makes you invisible to anyone you choose? 💍",
    "Do you want to find an ancient tome that holds the universe's secrets? 📖",
    "Do you want a balloon that lifts you to the edge of space? 🎈",
    "Do you want to uncover a hidden talent for telekinesis?",
    "Do you want a compass that points to what you need most at the moment? 🧭",
    "Do you want to have a tea that lets you see the future? 🍵",
    "Do you want a piece of chalk that draws doors you can step through?",
    "🚪",
    "Do you want to befriend a ghost with stories from centuries ago? 👻",
    "Do you want a hat that grants you the wisdom of the ages? 🧙‍♂️",
    "Do you want to find a locket that lets you revisit your happiest memory? 📸",
    "Do you want gloves that allow you to sculpt anything from air? 🧤",
    "Do you want to play a musical instrument that controls the weather? 🎶",
    "Do you want to find a pair of boots that take you to the end of the rainbow? 🌈",
    "Do you want to own a cloak that shields you from all harm? 🧥",
    "Do you want to discover a flower that blooms with every note you sing? 🌸",
    "Do you want to experience a moment of perfect harmony with the universe?",
    "Do you want a bracelet that changes color with the temperature? 🌡️",
    "Do you want to uncover a spell that brings drawings to life? 🖌️",
    "Do you want to find a way to communicate with past versions of yourself?",
    "Do you want a potion that can transform fear into courage?",
    "Do you want a new coffee mug?",
    "Do you want more time to relax?",
    "Do you want a surprise birthday party?",
    "Do you want a promotion at work?",
    "Do you want a pet dog?",
    "Do you want to learn a new language?",
    "Do you want a rainy day to stay in?",
    "Do you want a chance encounter with an old friend?",
    "Do you want a serene garden in your backyard?",
    "Do you want an unexpected financial windfall?",
    "Do you want a bookshelf filled with classics?",
    "Do you want a moment of fame?",
    "Do you want to experience zero gravity?",
    "Do you want a peaceful morning walk?",
    "Do you want a memorable adventure in a foreign land?",
    "Do you want a heartfelt compliment from a stranger?",
    "Do you want a quiet retreat to a mountain cabin?",
    "Do you want to witness a solar eclipse?",
    "Do you want to find a rare antique unexpectedly?",
    "Do you want a letter from a loved one?",
    "Do you want to master a musical instrument?",
    "Do you want an inspiring conversation with a mentor?",
    "Do you want a night sky full of stars?",
    "Do you want a breakthrough in a personal project?",
    "Do you want to discover a hidden talent?",
    "Do you want to be part of a historical event?",
    "Do you want an impromptu road trip?",
    "Do you want a meaningful gift that's not material?",
    "Do you want to solve a complex puzzle?",
    "Do you want a day without technology?",
    "Do you want to grow your own food?",
    "Do you want to swim in a crystal-clear lake?",
    "Do you want a long-lost friend to reach out?",
    "Do you want to create something that lasts generations?",
    "Do you want an evening of live jazz music?",
    "Do you want a spontaneous dance in the rain?",
    "Do you want to find a message in a bottle?",
    "Do you want a family heirloom passed down to you?",
    "Do you want a profound spiritual awakening?",
    "Do you want to witness an act of profound kindness?",
    "Do you want a day of complete silence?",
    "Do you want a friendship that withstands time and distance?",
    "Do you want to see a shooting star?",
    "Do you want a handwritten poem that speaks to your soul?",
    "Do you want to overcome a deep-seated fear?",
    "Do you want a moment of pure bliss?",
    "Do you want to see the Aurora Borealis?",
    "Do you want an unexpected visit from someone you miss?",
    "Do you want to master an ancient art form?",
    "Do you want a picnic in a secluded meadow?",
    "Do you want a day of pampering and luxury?",
    "Do you want to save someone's life?",
    "Do you want a secret garden to escape to?",
    "Do you want to hear words of wisdom from a child?",
    "Do you want a night of storytelling around a campfire?",
    "Do you want to discover a new star?",
    "Do you want a newfound sense of purpose?",
    "Do you want a new toothbrush?",
    "Do you want a plain notebook for jotting down to-dos?",
    "Do you want a simple black pen?",
    "Do you want a basic white t-shirt?",
    "Do you want a standard desk lamp?",
    "Do you want a regular coffee mug?",
    "Do you want a set of plain white socks?",
    "Do you want an ordinary wall clock?",
    "Do you want a generic calendar for the new year?",
    "Do you want a standard pillowcase?",
    "Do you want a basic plastic water bottle?",
    "Do you want a no-frills umbrella?",
    "Do you want a pair of standard kitchen scissors?",
    "Do you want a plain grey mouse pad?",
    "Do you want a simple door mat?",
    "Do you want a basic calculator for quick math?",
    "Do you want a standard stapler?",
    "Do you want a box of regular paper clips?",
    "Do you want a plain, unscented candle?",
    "Do you want a no-brand hand soap?",
    "Do you want a straightforward alarm clock?",
    "Do you want a set of plain dinner plates?",
    "Do you want a standard measuring tape?",
    "Do you want a basic plastic ruler?",
    "Do you want a simple can opener?",
    "Do you want a no-frills hairbrush?",
    "Do you want a pack of standard white envelopes?",
    "Do you want a plain black wallet?",
    "Do you want a generic phone charging cable?",
    "Do you want a basic bath towel?",
    "Do you want a standard ice cube tray?",
    "Do you want a simple key ring?",
    "Do you want a no-frills flashlight?",
    "Do you want a pack of plain sticky notes?",
    "Do you want a standard ballpoint pen refill?",
    "Do you want a basic USB stick for storage?",
    "Do you want a plain-colored phone case?",
    "Do you want a simple kitchen timer?",
    "Do you want a standard oven mitt?",
    "Do you want a plain notepad for grocery lists?",
    "Do you want a no-frills dishwashing sponge?",
    "Do you want a pack of standard batteries?",
    "Do you want a simple glass for water?",
    "Do you want a basic plastic storage container?",
    "Do you want a standard broom for cleaning?",
    "Do you want a plain black belt?",
    "Do you want a no-brand shampoo?",
    "Do you want a pack of plain printer paper?",
    "Do you want a simple nail clipper?",
    "Do you want a standard frying pan?"
  ]
}
