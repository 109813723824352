import { LitElement, css, html, nothing } from "lit";
import { customElement, state } from "lit/decorators";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { decrypt, readMessage } from "openpgp";
import { encrypted } from "./letter1.json";

@customElement("ha-letters")
export class HaLetters extends LitElement {
  @state()
  encrypted = true;

  @state()
  decrypted = "";

  @state()
  loading = false;

  __handleInput(evt: InputEvent) {
    const input = evt.target as HTMLInputElement;
    input.setCustomValidity("");
  }

  async __decrypt(evt: SubmitEvent) {
    evt.preventDefault();

    const form = evt.target as HTMLFormElement;
    const { password: passwordElement } = form;
    const password = passwordElement.value;

    this.loading = true;

    try {
      const byteCharacters = atob(encrypted);
      const byteArrays = [];

      for (let i = 0; i < byteCharacters.length; i += 1) {
        byteArrays.push(byteCharacters.charCodeAt(i));
      }

      const byteArray = new Uint8Array(byteArrays);

      const encryptedMessage = await readMessage({
        binaryMessage: byteArray,
      });

      const { data: decrypted } = await decrypt({
        message: encryptedMessage,
        passwords: [password.trim()],
        format: "binary",
      });

      const decoder = new TextDecoder();
      const decoded = decoder.decode(decrypted as Uint8Array);

      this.decrypted = decoded;
      this.encrypted = false;

      return decrypted;
    } catch (err) {
      console.error(err);
      passwordElement.setCustomValidity("Invalid password. Try again.");
      form.reportValidity();

      this.loading = false;
      return;
    }
  }

  __renderForm() {
    if (!this.encrypted) {
      return nothing;
    }

    return html`
      <form @submit=${this.__decrypt}>
        <input
          placeholder="Enter password..."
          name="password"
          type="password"
          required
          @input=${this.__handleInput}
        />
        <button ?disabled=${this.loading} type="submit">Decrypt</button>
      </form>
    `;
  }

  __renderContent(): string {
    if (this.encrypted) {
      return encrypted;
    }

    return unsafeHTML(this.decrypted) as string;
  }

  override render() {
    return html`
      ${this.__renderForm()}
      <article ?encrypted=${this.encrypted}>${this.__renderContent()}</article>
    `;
  }

  static override styles = css`
    article {
      max-width: 52ch;
      margin: 4rem auto;
    }

    article[encrypted] {
      word-break: break-all;
    }

    article header h1 {
      margin: 0;
    }

    small {
      font-size: 0.8rem;
    }

    form {
      background: black;
      padding: 2rem;
      border-radius: 0.5rem;
      display: flex;
      gap: 1rem;
      max-width: 24rem;
      margin: 0 auto;
      box-shadow: 0 0 4rem 1rem rgba(0, 0, 0, 0.25);
    }

    button {
      background: #55ee55;
      border: none;
      border-radius: 0.25rem;
      color: #000;
      font-size: 0.9rem;
      font-weight: bold;
      height: calc(2rem + 4px);
      margin: 0;
      padding: 0.25rem 1rem;
      vertical-align: middle;
    }

    button:hover {
      background: #33ff33;
    }

    button:disabled {
      background: #3b3b3b;
      color: #999;
    }

    input {
      background: #3b3b3b;
      border: 2px solid #55ee55;
      border-radius: 0.25rem;
      color: #fff;
      font: 0.9rem / 1 monospace;
      height: 2rem;
      margin: 0;
      padding: 0 0.5rem;
      vertical-align: middle;
      width: 100%;
    }
  `;
}
